import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { useI18n } from 'services/i18n';
import Layout from 'components/layout';
import SEO from 'components/seo';
import { Col, Container, Row } from 'styles/grid';
import { BiggerText, PrimaryTitle, Text } from 'components/texts';
import media from 'styles/media';
import colors from 'styles/colors';
import Calendar from 'images/icons/calendar.svg';
import CheckIcon from 'images/icons/check.svg';
import Mail from 'images/icons/mail.svg';
import { PrimaryButton } from 'components/buttons';

const MainIconRow = styled(Row)`
  margin-bottom: 40px;
  ${media.md`
    margin-bottom: 60px;
  `}
`;

const LeftCol = styled(Col)`
  text-align: center;
  ${media.md`
    text-align: right;
  `}
`;

const StyledLabel = styled(Text).attrs(() => ({
  as: 'label',
  weight: 'bold',
  mobileAlign: 'left',
  desktopAlign: 'left',
}))`
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
`;

const StyledInput = styled.input`
  color: ${colors.black};
  margin-top: 5px;
  height: 40px;
  padding-left: 20px;
  border: 1px solid ${colors.black};
  border-radius: 5px;
  ::placeholder {
    font-style: italic;
    color: ${colors.lightGrey};
  }
  &:invalid:not(:focus):not(:placeholder-shown) {
    border-color: ${colors.red};
  }
  &:valid {
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTdweCIgaGVpZ2h0PSIxNXB4IiB2aWV3Qm94PSIwIDAgMTcgMTUiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8ZyBpZD0iUmV2YW1wLTIwMjAiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJGSVRMRV9QbHVnaW5fMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTg0MC4wMDAwMDAsIC05MDkuMDAwMDAwKSIgZmlsbD0iIzAwQTFBQiIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGcgaWQ9IkFCT1ZFLVRIRS1GT0xEIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwgMTQwLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9IkludHJvIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwgNTAzLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgIDxnIGlkPSJvdXRjb21lcyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoODQwLjAwMDAwMCwgMTE4LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgICAgICA8ZyBpZD0ib3V0Y29tZS0xIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwgMTQyLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgPGcgaWQ9ImNoZWNrIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwgNi4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMC4zODQ0MjY1MDMsOS40ODQyNzI5NCBDLTAuODYyMjk4NDE5LDguMTE0NDI4MSAxLjIxNTU3NjQ1LDYuMTQ1Mjc2MTUgMi40NjIzMDEzNyw3LjUxNTEyMDk5IEw1Ljg3MDAxNjE2LDExLjM2NzgwOTYgTDE1Ljc2MDcwMDUsMC4zMjM0MzU1ODcgQzE2LjQyNTYyMDUsLTAuMzYxNDg2ODMyIDE3LjI1Njc3MDQsMC4xNTIyMDQ5ODIgMTYuOTI0MzEwNSwwLjgzNzEyNzQwMSBMNy4xMTY3NDEwOCwxNC4zNjQzNDUyIEM2LjYxODA1MTExLDE1LjEzNDg4MjkgNS42MjA2NzExNywxNS4yMjA0OTgyIDQuOTU1NzUxMjEsMTQuNTM1NTc1OCBMMC4zODQ0MjY1MDMsOS40ODQyNzI5NCBaIiBpZD0iUGF0aCI+PC9wYXRoPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: 16px 18px;
    background-position: 98% 50%;
    border-color: ${colors.lightFitle};
  }
  :focus,
  :active {
    outline: none;
    border-color: ${colors.fitle};
  }
`;

const StyledSelect = styled.select`
  cursor: pointer;
  margin-top: 5px;
  height: 40px;
  padding: 0 20px;
  border: 1px solid ${colors.black};
  border-radius: 5px;
  color: ${props => (props.value === '' ? colors.lightGrey : colors.black)};
  font-style: ${props => (props.value === '' ? 'italic' : 'normal')};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 95% 55%;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMCA1Ij4KICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgPHBhdGggaWQ9ImNhcnJldCIgZmlsbD0iIzE4MTgxOCIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNMCAwbDUgNSA1LTV6Ii8+CiAgICA8cGF0aCBkPSJNLTctMTBoMjR2MjRILTd6Ii8+CiAgPC9nPgo8L3N2Zz4K');

  :hover {
    border-color: ${colors.lightFitle};
  }
  ${props =>
    props.value !== '' &&
    css`
      background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTdweCIgaGVpZ2h0PSIxNXB4IiB2aWV3Qm94PSIwIDAgMTcgMTUiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8ZyBpZD0iUmV2YW1wLTIwMjAiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJGSVRMRV9QbHVnaW5fMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTg0MC4wMDAwMDAsIC05MDkuMDAwMDAwKSIgZmlsbD0iIzAwQTFBQiIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGcgaWQ9IkFCT1ZFLVRIRS1GT0xEIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwgMTQwLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9IkludHJvIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwgNTAzLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgIDxnIGlkPSJvdXRjb21lcyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoODQwLjAwMDAwMCwgMTE4LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgICAgICA8ZyBpZD0ib3V0Y29tZS0xIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwgMTQyLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgPGcgaWQ9ImNoZWNrIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwgNi4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMC4zODQ0MjY1MDMsOS40ODQyNzI5NCBDLTAuODYyMjk4NDE5LDguMTE0NDI4MSAxLjIxNTU3NjQ1LDYuMTQ1Mjc2MTUgMi40NjIzMDEzNyw3LjUxNTEyMDk5IEw1Ljg3MDAxNjE2LDExLjM2NzgwOTYgTDE1Ljc2MDcwMDUsMC4zMjM0MzU1ODcgQzE2LjQyNTYyMDUsLTAuMzYxNDg2ODMyIDE3LjI1Njc3MDQsMC4xNTIyMDQ5ODIgMTYuOTI0MzEwNSwwLjgzNzEyNzQwMSBMNy4xMTY3NDEwOCwxNC4zNjQzNDUyIEM2LjYxODA1MTExLDE1LjEzNDg4MjkgNS42MjA2NzExNywxNS4yMjA0OTgyIDQuOTU1NzUxMjEsMTQuNTM1NTc1OCBMMC4zODQ0MjY1MDMsOS40ODQyNzI5NCBaIiBpZD0iUGF0aCI+PC9wYXRoPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=');
      background-repeat: no-repeat;
      background-size: 16px 18px;
      background-position: 98% 50%;
      border-color: ${colors.lightFitle};
    `}
`;

const StyledOption = styled.option`
  color: ${colors.black};
  font-style: normal;
`;

const StyledLi = styled.li`
  :not(last-child) {
    margin-bottom: 10px;
  }
`;

const Check = styled(CheckIcon)`
  height: 1rem;
  width: 1rem;
  margin-right: 10px;
`;

const Partnership = styled(Text)`
  display: flex;
  align-items: center;
  margin: 5px 0px;
  text-decoration: none;
  background-color: ${colors.white};
  svg {
    height: 2rem;
    width: 2rem;
    margin-right: 10px;
  }
`;

const INITIAL_VALUES = {
  fullName: '',
  email: '',
  tel: '',
  companyName: '',
  siteUrl: '',
  formSentSucceed: false,
  monthlySales: '',
  inboundOrigin: '',
  inboundOriginDetails: '',
};

const MONTHLY_SALES_VALUES = [
  ['LessThan', 500],
  ['UpTo', 2000],
  ['UpTo', 5000],
  ['MoreThan', 5000],
];

const INBOUND_ORIGIN_VALUES = [
  'socialNetwork',
  'emailCampaign',
  'website',
  'webinar',
  'newsletter',
  'clientRecommendation',
  'mediaArticle',
  'event',
  'other',
];

const DemoPage = () => {
  const [t, language] = useI18n();
  const [values, setValues] = useState(INITIAL_VALUES);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues(prevValues => ({ ...prevValues, [name]: value }));
  };

  const handleInboundOriginChange = e => {
    const { value } = e.target;
    if (value !== 'other') {
      setValues(prevValues => ({ ...prevValues, inboundOriginDetails: '' }));
    }
    handleInputChange(e);
  };

  const handleSubmit = e => {
    e.preventDefault();
    fetch(process.env.GATSBY_API_URL + '/v2/fitle_website/ask_demo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrer: 'client',
      body: JSON.stringify({
        full_name: values.fullName,
        email: values.email,
        tel: values.tel,
        company_name: values.companyName,
        site_url: values.siteUrl,
        monthly_sales: values.monthlySales,
        inbound_origin: values.inboundOriginDetails || values.inboundOrigin,
      }),
    })
      .then(() => {
        setValues({ ...INITIAL_VALUES, formSentSucceed: true });
      })
      .catch(e => {
        alert(`Something went wrong. Please retry:\n ${e}`);
        setValues(INITIAL_VALUES);
      });
  };

  return (
    <Layout>
      <SEO title={t('seo.title.demo')} lang={language} description={t('seo.description.demo')} />
      <section>
        <Container>
          <MainIconRow justify="center">
            <Col md="content" style={{ textAlign: 'center' }}>
              <Calendar height="4rem" width="4rem" />
            </Col>
          </MainIconRow>
          <Row justify="center">
            <Col md="content">
              <PrimaryTitle align="center">{t('demo.title')}</PrimaryTitle>
            </Col>
          </Row>
          <Row justify="center">
            <Col sm={12} md={10} lg={9}>
              <BiggerText desktopAlign="center">{t('demo.caption')}</BiggerText>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row justify="space-around">
            <LeftCol sm={12} md={5}>
              <form onSubmit={handleSubmit} style={{ flexDirection: 'column', display: 'flex' }}>
                <StyledLabel>
                  {t('demo.fullNameInputLabel')}*
                  <StyledInput
                    id="ga-input-name"
                    type="text"
                    name="fullName"
                    value={values.fullName}
                    placeholder={t('demo.fullNameInputPlaceholder')}
                    onChange={handleInputChange}
                    required
                  />
                </StyledLabel>
                <StyledLabel>
                  {t('demo.emailInputLabel')}*
                  <StyledInput
                    id="ga-input-email"
                    type="email"
                    name="email"
                    value={values.email}
                    placeholder={t('demo.emailInputPlaceholder')}
                    onChange={handleInputChange}
                    required
                  />
                </StyledLabel>
                <StyledLabel>
                  {t('demo.telInputLabel')}*
                  <StyledInput
                    id="ga-input-tel"
                    type="tel"
                    name="tel"
                    value={values.tel}
                    placeholder={t('demo.telInputPlaceholder')}
                    onChange={handleInputChange}
                    minLength={8}
                    required
                  />
                </StyledLabel>
                <StyledLabel>
                  {t('demo.companyNameInputLabel')}*
                  <StyledInput
                    id="ga-input-company-name"
                    type="text"
                    name="companyName"
                    value={values.companyName}
                    placeholder={t('demo.companyNameInputPlaceholder')}
                    onChange={handleInputChange}
                    required
                  />
                </StyledLabel>
                <StyledLabel>
                  {t('demo.siteUrlInputLabel')}*
                  <StyledInput
                    id="ga-input-url"
                    type="text"
                    name="siteUrl"
                    value={values.siteUrl}
                    placeholder={t('demo.siteUrlInputPlaceholder')}
                    onChange={handleInputChange}
                    required
                  />
                </StyledLabel>
                <StyledLabel>
                  {t('demo.inboundOriginInputLabel')}
                  <StyledSelect
                    id="ga-input-inbound-origin"
                    name="inboundOrigin"
                    value={values.inboundOrigin}
                    onChange={handleInboundOriginChange}
                    required
                  >
                    <StyledOption value="">{t('demo.inboundOriginInputPlaceholder')}</StyledOption>
                    {INBOUND_ORIGIN_VALUES.map(text => (
                      <StyledOption key={text} value={text}>
                        {t('demo.' + text)}
                      </StyledOption>
                    ))}
                  </StyledSelect>
                </StyledLabel>
                {values.inboundOrigin === 'other' && (
                  <StyledLabel>
                    {t('demo.inboundOriginDetailsInputLabel')}*
                    <StyledInput
                      id="ga-input-input-inbound-origin-details"
                      type="text"
                      name="inboundOriginDetails"
                      value={values.inboundOriginDetails}
                      placeholder={t('demo.inboundOriginDetailsInputPlaceholder')}
                      onChange={handleInputChange}
                      required
                    />
                  </StyledLabel>
                )}
                <StyledLabel>
                  {t('demo.monthlySalesLabel')}
                  <StyledSelect
                    id="ga-input-monthly-sales"
                    name="monthlySales"
                    value={values.monthlySales}
                    onChange={handleInputChange}
                  >
                    <StyledOption value="">{t('demo.selectActivity')}</StyledOption>
                    <StyledOption value="no-activity">{t('demo.noActivity')}</StyledOption>
                    {MONTHLY_SALES_VALUES.map(([text, value], index) => (
                      <StyledOption key={index} value={text + ' ' + value}>
                        {t({
                          id: 'demo.activity' + text,
                          values: {
                            sales: value,
                          },
                        })}
                      </StyledOption>
                    ))}
                  </StyledSelect>
                </StyledLabel>
                <PrimaryButton
                  id="ga-demo-submit"
                  style={{ marginTop: '40px' }}
                  text={t('demo.submit')}
                  type="submit"
                />
              </form>
              <Text mobileAlign="left">{t('demo.required')}*</Text>
              {values.formSentSucceed && (
                <BiggerText desktopAlign="center" weight="bold">
                  {t('demo.confirmation')}
                </BiggerText>
              )}
            </LeftCol>
            <Col sm={12} md={5}>
              <BiggerText
                style={{ marginTop: '50px' }}
                color="fitle"
                weight="bold"
                mobileAlign="left"
              >
                {t('demo.rightChecklistTitle')}
              </BiggerText>
              <ul>
                <StyledLi>
                  <Check />
                  {t('demo.rightChecklistItem1')}
                </StyledLi>
                <StyledLi>
                  <Check />
                  {t('demo.rightChecklistItem2')}
                </StyledLi>
                <StyledLi>
                  <Check />
                  {t('demo.rightChecklistItem3')}
                </StyledLi>
              </ul>
              <BiggerText
                style={{ marginTop: '50px' }}
                color="fitle"
                weight="bold"
                mobileAlign="left"
              >
                {t('demo.question')}
              </BiggerText>
              <Text style={{ margin: '5px 0px' }} mobileAlign="left">
                {t('demo.contact')}
              </Text>
              <Partnership as="a" href="mailto:partnerships@fitle.com" mobileAlign="left">
                <Mail />
                partnerships@fitle.com
              </Partnership>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default DemoPage;
